<template>
    <div class="PermissionBatchManagement">
        <el-page-header @back="backPrivious" content="权限批量管理"></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <!-- 标题 -->
                <div class="banner">
                    {{ enterpriseStateInfo.enterpriseName }}
                </div>
                <!-- 搜索 -->
                <div class="f-c-b feature">
                    <div class="search_input">
                        <input type="text" placeholder="请输入员工名称搜索" v-model.trim="searchValue" @keyup.enter="enterSearch"
                            maxlength="10" />
                        <el-button class="search_icon" @click="search" icon="el-icon-search"></el-button>
                    </div>
                    <div class="Btnbox f-c-c">
                        <div class="addBtn f-c-c"  @click="batchSettingPremission"></div>
                        <div @click="drawer = true" class="screenBtn"
                            :style="{ display: isShowScreenBtn === 'D' ? 'none' : 'block' }">
                            <i class="el-icon-finished"></i>
                            <span>筛选</span>
                        </div>
                    </div>
                </div>
                <!-- 列表 -->
                <el-checkbox-group v-if="UserList" class="list f-w" v-model="recordeIdList" text-color="#333">
                    <div class="item f-c" :style="{ marginRight: (index % 2 == 0) ? '20px' : '0' }"
                        v-for="(item, index) of UserList" :key="index">
                        <el-checkbox :label="item.recordeId">
                            <div></div>
                        </el-checkbox>
                        <img class="userAvatarUrl" :src="item.userAvatarUrl" alt="">
                        <div class="f-cl-b textBox">
                            <!-- <span>{{ item }}</span> -->
                            <span>{{ item.ecardName }}</span>
                            <span>联系方式：{{ item.ecardPhone }}</span>
                            <span>所属部门：{{ item.ecardNote ? item.ecardNote : '未设置' }}</span>

                        </div>
                        <div class="checkbtn" @click="toEmployeeInfo(item.recordeId)">查看</div>
                    </div>
                </el-checkbox-group>
                
                <el-empty v-else description="搜索不到该员工！" class="no-list"></el-empty>
                <Pagination v-if="UserList" :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
            @pagination="fetchData" />
            </div>
        </div>
        
        <PowerDrawer :drawer="drawer" @submitForm="submitForm" @resetForm="resetForm" @closeDrawer="closeDrawer">
        </PowerDrawer>
        <PowerDrawer :drawer="batchDrawer" :type="'batchSettingPower'" :companyInfoNoAvailable="true"
            @enterpriseUserRevisePermissionList="enterpriseUserRevisePermissionList" @closeDrawer="closeBatchDrawer">
        </PowerDrawer>
    </div>
</template>

<script>
import { PaginationRetention1 } from '@/mixins/PaginationRetentionMixin';
import { ChineseCharactersInputMixin } from '@/mixins/ChineseCharactersInputMixin';
export default {
    name: 'PermissionBatchManagement',
    components: {
        NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue'),
        PowerDrawer: () => import(/* webpackChunkName: "PowerDrawer" */ '@/pages/SupplyChainManagement/personnelManagement/components/PowerDrawer.vue')
    },
    mixins: [PaginationRetention1, ChineseCharactersInputMixin],
    data() {
        return {
            UserList: [],//审批列表
            drawer: false,
            batchDrawer: false,
            userPermission: {
                "a_m": "",//人员权限管理
                "c_m": "",//客户管理
                "e_i": "",//企业信息
                "f_s": "",//财务统计
                "i_m": "",//库存管理
                "o_m": "",//订单管理
                "p_c": "",//工作牌
                "p_l": "",//产品库
                "p_m": ""//人员管理
            },
            isShowScreenBtn: '',//是否显示赛选盒子
            searchEmployeePhone: '',
            multipleSelection: [],//筛选选中列表
            recordeIdList: [],//用于筛选时将企业用户编号集合当作字段提交
            enterpriseStateInfo: ''
        }
    },
    created() {
        this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo')
        let premissionInfo = this.$getStorage('enterpriseStateInfo');
        this.isShowScreenBtn = premissionInfo.userPermission.a_m;
        this.recordeIdList = this.$getStorage('keepmultipleSelection') ?? [];
        this.getDate();
    },
    methods: {
        getDate() {
            this.enterpriseUserList(this.paging, this.pagingSize)
        },
        search() {
            this.enterpriseUserList()
        },
        // 企业用户列表接口
        enterpriseUserList(page = this.page, size = this.size) {
            let that = this;
            that.$http.enterpriseUserList({
                userId: parseInt(that.$store.state.userId),
                // token: that.$getStorage('token'),
                page,
                size,
                ownerStatus:"Y",
                ecardName: that.searchValue,
                userPermission: that.userPermission
            }).then(res => {
                let list = [];
                res.data.records.forEach((item) => {
                    if (item.dcPermissionFlag != 'Y') {
                        list.push(item)
                    }
                })
                that.UserList = list;
                that.total = res.data.total;
                that.currentPage = res.data.current;
                that.pages = res.data.pages;
            })
        },
        // 提交按钮
        submitForm(data) {
            this.userPermission = data
            this.enterpriseUserList()
            this.$common.message('筛选成功！', 'success')
            this.drawer = false
        },
        // 重置按钮
        resetForm(data) {
            this.userPermission = data
        },
        // 关闭从右弹出的抽屉
        closeDrawer(data) {
            this.drawer = data
        },
        // 关闭从右弹出的抽屉
        closeBatchDrawer(data) {
            this.batchDrawer = data
        },

        // 对象去重：
        OjeDeRepeat(arr) {
            var result = [];
            var obj = {};
            for (let i = 0; i < arr.length; i++) {
                //利用缓存对象的属性去重
                if (!obj[JSON.stringify(arr[i])]) {
                    result.push(arr[i]);
                    obj[JSON.stringify(arr[i])] = true;
                }
            }
            return result
        },
        // 全选时用于获取每个选项中的id集合
        handleSelectionChange(val) {
            let that = this,
                list = that.$getStorage('keepmultipleSelection'),
                selectList = val.concat(list),
                objList = that.OjeDeRepeat(selectList);
            that.$setStorage('keepmultipleSelection', objList)
            that.multipleSelection = objList
            let recordeIdList = [];
            val.forEach((item) => {
                recordeIdList.push(item.recordeId)
            })
            that.recordeIdList = recordeIdList
        },
        // 全选时不触发当前项 -- 选中当前项
        selectItem(selection) {
            this.multipleSelection = selection
            this.$setStorage('keepmultipleSelection', selection)
        },
        // 删除选中
        handleDelete(row) {
            let id = row.recordeId,
                multipleSelectionFilter = this.multipleSelection.filter(item => item.recordeId != id)
            this.multipleSelection = multipleSelectionFilter;
            this.$setStorage('keepmultipleSelection', multipleSelectionFilter)
            this.recordeIdList = this.recordeIdList.filter(item => item != id);
            this.UserList.forEach(item => {
                if (item.recordeId == id) {
                    this.$refs.multipleTable.toggleRowSelection(item, false);
                }
            })

        },
        // 全选
        selectAll(select) {
            if (!select.length) {
                this.$setStorage('keepmultipleSelection', [])
                this.multipleSelection = []
            } else {
                this.multipleSelection = select
                this.$setStorage('keepmultipleSelection', select)
            }
        },
        // 批量设置
        batchSettingPremission() {
            let that = this;
            console.log(that.recordeIdList);
            if (that.recordeIdList.length) {
                that.batchDrawer = true
            } else {
                that.$common.message('请选择人员！', 'warning')
            }
        },
        // 企业用户批量设置权限接口
        enterpriseUserRevisePermissionList(userPermission) {
            console.log(this.recordeIdList);
            let that = this;
            that.$http.enterpriseUserRevisePermissionList({
                userId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                recordeIdList: that.recordeIdList,
                userPermission
            }).then(res => {
                that.$common.message(res.msg, 'success')
                that.multipleSelection = [];//清空选中列表
                that.recordeIdList = [];//清空选中ID列表
                that.$setStorage('keepmultipleSelection', [])//清空存储在本地中选中的列表
                that.searchValue = '';//清空输入框内容
                // that.$refs.multipleTable.clearSelection();//清空勾选项
                that.batchDrawer = false;//关闭弹出抽屉

            })
        },
        toEmployeeInfo(id) {
            this.$router.push({
                name: 'EmployeeInfo',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        id,
                        type: 'employeeInfo'
                    }))
                }
            })
        }
    },
}

</script>
<style lang='less' scoped>
.PermissionBatchManagement {
    .main {
        width: 980px;

        .banner {
            background: url('../../../assets/supply/supplyBanner.png');
            height: 100px;
            color: #FFFFFF;
            font-size: 30px;
            line-height: 100px;
            text-align: center;
            margin-bottom: 40px;
        }

        .feature {
            margin-bottom: 20px;

            .search_input {
                input {
                    background-color: #FFF;
                    border-radius: 4px 0 0 4px;
                    border: 1px solid #E0E1E3;
                    box-sizing: border-box;
                    color: #606266;
                    display: inline-block;
                    height: 50px;
                    line-height: 50px;
                    outline: 0;
                    padding: 0 20px;
                    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
                    width: 400px;
                    border-right: none;
                    font-size: 16px;
                }

                .search_icon {
                    width: 80px;
                    height: 50px;
                    border-top-right-radius: 4px !important;
                    border-top-left-radius: 0px !important;
                    border-bottom-right-radius: 4px !important;
                    border-bottom-left-radius: 0px !important;
                    color: #FFFFFF;
                    background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
                }
            }

            .Btnbox {
                .addBtn {
                    font-size: 18px;
                    width: 120px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    color: #FFFFFF;
                    border-radius: 10px;
                    background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
                    cursor: pointer;
                    background: url('../../../assets/supply/batch.png');

                    // i {
                    //     margin-right: 10px;
                    // }
                }

                .screenBtn {
                    margin-left: 20px;
                    font-size: 18px;
                    width: 120px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    color: #FFFFFF;
                    border-radius: 10px;
                    background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
                    cursor: pointer;

                    i {
                        margin-right: 10px;
                    }
                }
            }

        }

        .list {
            .item {
                width: 440px;
                height: 100px;
                background: #F8F8F8;
                border-radius: 10px;
                margin-bottom: 20px;
                padding: 20px 10px;
                position: relative;

                .userAvatarUrl {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                }

                .textBox {
                    height: 80px;
                    width: 220px;
                    margin-left: 10px;
                    font-size: 16px;
                    color: #000000;
                    line-height: 18px;

                    .name {
                        font-size: 18px;
                        font-weight: 400;
                        color: #000000;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .type {
                        font-size: 16px;
                        font-weight: 400;
                        color: #999999;
                        margin-bottom: 24px;
                    }

                    span {
                        width: 100%;
                    }

                }

                .checkbtn {
                    position: absolute;
                    top: 50px;
                    right: 20px;
                    width: 80px;
                    height: 40px;
                    border: 1px solid #1785F6;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 40px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #1785F6;
                    cursor: pointer;
                }
            }
        }
    }

    .input-box {
        .search-btn {
            margin-left: 20px;
            padding: 12px 30px;
        }

        .screen {
            width: 120px;
            height: 40px;
            margin-left: 20px;
            padding: 0;

            .el-icon-finished {
                font-size: 20px;
            }
        }

        .screen:hover {
            cursor: pointer;
        }
    }

    .left-table {
        margin-top: 30px;
        margin-right: 20px;
        align-self: flex-start;
    }

    .right-table {
        border: 1px solid #EBEEF5;
        margin-top: 30px;
    }

    .cell {
        .item {
            padding: 5px;

            .item-image {
                width: 85px;
                height: 85px;
                border-radius: 50%;
            }

            .baseInfo {
                margin-left: 30px;

                span {
                    font-size: 14px;
                }

                span:first-child {
                    font-size: 20px;
                }

                span:nth-child(2) {
                    margin: 10px 0;
                }
            }

            .state {
                text-align: right;
                color: red;
                margin-right: 20px;
            }
        }

    }

    /deep/ .el-table .el-table__cell {
        padding: 8px 0 !important;
    }
}
</style>
